import Visitor from './visitor';

export default class PricelistAndOrder {
  static init() {
    PricelistAndOrder.updateContentIfNecessary();

    window.document.addEventListener('preferences-registered', () => {
      PricelistAndOrder.updateContentIfNecessary();
    });
  }

  static updateContentIfNecessary() {
    const sport = Visitor.getSport();

    if (sport && (sport.id === 'other' || sport.id === 'OTHER')) {
      PricelistAndOrder.showForm();
    } else if (sport) {
      $.ajax('/wp-json/sportlink/v1/show-order-form', {
        cache: false,
        dataType: 'json',
        processData: false,
        data: JSON.stringify(sport),
        method: 'POST',
        success: (data) => {
          if (data && data.showForm) {
            PricelistAndOrder.showForm();
          } else {
            PricelistAndOrder.hideForm();
          }
        },
      });
    } else {
      PricelistAndOrder.hideForm();
    }
  }

  static showForm() {
    $('.order-form-container').addClass('show');
  }

  static hideForm() {
    $('.order-form-container').removeClass('show');
  }
}
