export default class ACFMap {
  static Init() {
    $('.acf-map').each((index, el) => {
      ACFMap.initMap($(el));
    });
  }
  
  /**
  * initMap
  *
  * Renders a Google Map onto the selected jQuery element
  *
  * @param   jQuery $el The jQuery element.
  * @return  object The map instance.
  */
  static initMap($el) {
    // Find marker elements within map.
    const $markers = $el.find('.marker');
    
    // Create generic map.
    const mapArgs = {
      zoom: $el.data('zoom') || 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
    };
    const map = new google.maps.Map($el[0], mapArgs);
    
    // Add markers.
    map.markers = [];
    $markers.each((index, marker) => {
      ACFMap.initMarker($(marker), map);
    });

    // Center map based on markers.
    ACFMap.centerMap(map);

    // Return map instance.
    return map;
  }
  
  /**
  * initMarker
  *
  * Creates a marker for the given jQuery element and map.
  *
  * @param   jQuery $el The jQuery element.
  * @param   object The map instance.
  * @return  object The marker instance.
  */
  static initMarker($marker, map) {
    // Get position from marker.
    const lat = $marker.data('lat');
    const lng = $marker.data('lng');
    const latLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };
    
    // Create marker instance.
    const marker = new google.maps.Marker({
      position: latLng,
      map,
      title: $marker.data('title'),
    });
    
    
    // If marker contains HTML, add it to an infoWindow.
    if ($marker.html()) {
      // Create info window.
      marker.infowindow = new google.maps.InfoWindow({
        content: $marker.html(),
      });
      
      // Show info window when marker is clicked.
      google.maps.event.addListener(marker, 'click', () => {
        map.markers.forEach((markerObj) => {
          markerObj.infowindow.close();
        });

        marker.infowindow.open(map, marker);
      });
    }

    // Append to reference for later use.
    map.markers.push(marker);
  }
  
  /**
  * centerMap
  *
  * Centers the map showing all markers in view.
  *
  * @param   object The map instance.
  * @return  void
  */
  static centerMap(map) {
    // Create map boundaries from all map markers.
    const bounds = new google.maps.LatLngBounds();
    map.markers.forEach((marker) => {
      bounds.extend({
        lat: marker.position.lat(),
        lng: marker.position.lng(),
      });
    });
    
    // Case: Single marker.
    if (map.markers.length === 1) {
      map.setCenter(bounds.getCenter());
      
      // Case: Multiple markers.
    } else {
      map.fitBounds(bounds);
      map.setZoom(12);
    }
  }
}
