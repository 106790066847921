import Visitor from './visitor';

export default class SelectSport {
  static init() {
    $(document).on('change', '.select-sport select', e => SelectSport.chooseSport(e));

    window.document.addEventListener('sport-saved', () => {
      const sport = Visitor.getSport();

      if (sport && sport.id) {
        $('.select-sport select').val(sport.id);
      }
    });
  }

  static chooseSport(e) {
    const selectElement = $(e.currentTarget);
    const selectedSport = selectElement.find('option:selected');
    let sport = null;

    if (selectedSport.val() !== '') {
      sport = {
        id: selectedSport.val(),
        name: selectedSport.text().trim(),
      };
    }

    SelectSport.saveSport(sport, selectElement);
  }

  static saveSport(sport, selectElement) {
    if (!Visitor.saveSport(sport)) {
      SelectSport.showTheSportCouldNotBeSavedMessage(selectElement);
    }
  }

  static showTheSportCouldNotBeSavedMessage(selectElement) {
    selectElement.next('.could-not-save-sport-for-sport-selector').show();
  }

  static hideTheSportCouldNotBeSavedMessage() {
    $('.could-not-save-sport-for-sport-selector').hide();
  }
}
