export default class Visitor {
  constructor() {
    this.sport = Visitor.getSport();
    this.club = Visitor.getClub();
  }

  static shouldRegisterPreferences() {
    let shouldRegister = false;

    try {
      shouldRegister = !window.sessionStorage.getItem('preferences-registered');
    } catch (exception) {
      shouldRegister = true;
    }

    return shouldRegister;
  }

  static registerPreferences() {
    const visitor = new Visitor();
  
    $.ajax('/wp-json/sportlink/v1/register-preferences', {
      cache: false,
      dataType: 'json',
      processData: false,
      data: JSON.stringify(visitor),
      method: 'POST',
      success: (data) => {
        if (data) {
          window.document.dispatchEvent(new CustomEvent('preferences-registered'));
        }
      },
    });

    try {
      window.sessionStorage.setItem('preferences-registered', true);
    } catch (exception) {
      return false;
    }

    return true;
  }

  static getSport() {
    let sport = null;

    try {
      const sportJson = window.localStorage.getItem('sport');
      sport = sportJson ? JSON.parse(sportJson) : null;
    } catch (exception) {
      return null;
    }
    
    return sport;
  }

  static saveSport(sport) {
    const sportJson = JSON.stringify(sport);
    
    try {
      window.localStorage.setItem('sport', sportJson);
      window.document.dispatchEvent(new CustomEvent('sport-saved'));
      
      Visitor.registerPreferences();
    } catch (exception) {
      return false;
    }

    return true;
  }

  static getClub() {
    let club = null;

    try {
      const clubJson = window.localStorage.getItem('club');
      club = clubJson ? JSON.parse(clubJson) : null;
    } catch (exception) {
      return null;
    }
    
    return club;
  }

  static saveClub(club) {
    const clubJson = JSON.stringify(club);
    
    try {
      window.localStorage.setItem('club', clubJson);
      Visitor.registerPreferences();
    } catch (exception) {
      return false;
    }

    if (club.sportId) {
      Visitor.saveSport({ id: club.sportId });
    }

    return true;
  }
}
