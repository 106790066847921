import * as Cookies from 'js-cookie';
import Swiper, {
  Autoplay,
  Pagination,
  EffectFade,
  Navigation,
}
  from 'swiper/core';
import ACFMap from './acf-map';
import Start from './start';
import Visitor from './visitor';
import SmartContent from './smart-content';
import SelectSport from './select-sport';
import PricelistAndOrder from './pricelist-and-order';

Swiper.use([Autoplay, Pagination, EffectFade, Navigation]);

class Sportlink {
  /**
   * Sets necessary variables for this class
   * that are used throughout
   */
  constructor() {
    this.loadingOverlay = $('.loading-overlay');
  }

  /**
   * Initializes all needed functions
   */
  init() {
    $('html').attr('class', 'js');
    $(window).on('scroll load', () => Sportlink.setOffsetClass());

    if (Visitor.shouldRegisterPreferences()) {
      Visitor.registerPreferences();
    }

    this.removeLoadingOverlay();

    $('.notification .close').on('click', e => Sportlink.dismissNotification(e));
    $(document).on('change', '.include-tv .switch-input', e => Sportlink.toggleClubTVPrice(e));

    SmartContent.init();
    Start.init();
    SelectSport.init();
    Sportlink.initLogoCarousel();
    PricelistAndOrder.init();

    // Remove Empty Rows
    $('meta[name="no-news-to-display"][content="true"]').closest('.vc_row').remove();


    this.acfMap = new ACFMap.Init();

    $('.main > .hero:first-child, .main > .container > .vc_row.row-hero:first-child')
      .parents('body')
      .addClass('has-hero');

    Sportlink.animateHeroText();

    // $('.dropdown-custom-toggle').dropdown({
    //   offset: 50,
    //   boundary: $('.navbar-main')[0],
    //   reference: $('.navbar-main')[0],
    // });

    // $.fn.dropdown.Constructor.Default.boundary = $('.navbar-main')[0];
  }

  removeLoadingOverlay() {
    this.loadingOverlay.hide(0);
  }

  static setOffsetClass() {
    const top = $(window).scrollTop();
    $('body').toggleClass('scroll', top > 0);
  }
  
  static dismissNotification(e, days = 7) {
    const notification = $(e.currentTarget).parents('.notification');
    const notificationId = notification.data('id');

    Cookies.set(`notification-dismiss-${notificationId}`, 1, { expires: days });
  }

  static initLogoCarousel() {
    $('.image-carousel').each(function createSwiper() {
      /* eslint-disable no-new */
      new Swiper($('.swiper-container', this)[0], {
        slidesPerView: 2,
        slidesPerGroup: 2,
        loop: true,
        spaceBetween: 32,
        allowTouchMove: true,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        breakpoints: {
          481: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          768: {
            slidesPerView: 6,
            slidesPerGroup: 6,
          },
          992: {
            slidesPerView: $(this).data('slides-per-view'),
            slidesPerGroup: $(this).data('slides-per-view'),
          },
        },
      });
      /* eslint-enable no-new */
    });
  }

  static toggleClubTVPrice(e) {
    const switchInput = $(e.currentTarget);
    const target = $('[data-price-tv]', switchInput.parents('.package').eq(0));

    target.each(function togglePrice() {
      const originalPrice = $(this).attr('data-price') || 0;

      if (switchInput.prop('checked')) {
        $(this)[0].innerHTML = $(this).data('price-tv');
      } else {
        $(this)[0].innerHTML = originalPrice;
      }
    });
  }

  static animateHeroText() {
    const items = $('.hero .animate-text, .row-hero .animate-text');
    let index = 0;

    // setup a function to show the current one and increase the index
    const loop = () => {
      items.removeClass('animate__flipInX');
      items.eq(index).addClass('animate__flipInX');

      // increment the index - if it is beyond the number of li's - reset it to 0
      index += 1;
      if (index >= items.length) index = 0;
    };

    // call it once as we load
    loop();

    // set it up to be called every # ms
    setInterval(loop, 5000);
  }
}

$(() => {
  const sl = new Sportlink();
  sl.init();
});

// initialize on window load to make sure 
// WPBakery scripts have done their thing in position full width rows
window.addEventListener('load', () => {
  const stickyBottomRows = document.querySelectorAll('.sticky-row-bottom');
    
  if (stickyBottomRows && stickyBottomRows.length) {
    setTimeout(() => {
      stickyBottomRows.forEach((stickyRow) => {
        stickyRow.classList.add('sticky-row-bottom-applied');
      });
    }, 250);
  }
});
