import autocomplete from 'autocompleter';
import Visitor from './visitor';

export default class Start {
  static init() {
    Start.initializeClubSelector();
    
    const sport = Visitor.getSport();
    const club = Visitor.getClub();

    if (sport) {
      Start.setSport(sport);
    }

    if (club && club.name) {
      $('.club-selector').val(club.name);
    }

    $('[data-choose-sport-id]').on('click', e => Start.chooseSport(e));
    $('.start-menu .return-to-start').on('click', e => Start.returnToStart(e));
    $('.start-menu .forget-sport').on('click', e => Start.forgetSport(e));

    window.document.addEventListener('sport-saved', () => {
      Start.setSport(Visitor.getSport());
    });

    $('.start-menu .dropdown-menu').on('click', (e) => {
      e.stopPropagation();
    });
  }

  static initializeClubSelector() {
    $('.club-selector').on('focus', () => Start.hideTheClubIsSavedMessage());

    autocomplete({
      input: $('.club-selector')[0],
      emptyMsg: 'Er zijn helaas geen clubs gevonden.',
      cssClass: 'autocomplete-results',
      minLength: 3,
      debounceWaitMs: 1000,
      fetch: (text, update) => {
        $('.club-selector-spinner').show();

        $.ajax({
          cache: false,
          method: 'GET',
          dataType: 'json',
          url: `/wp-json/sportlink/v1/get-clubs?q=${text}`,
          success: (data) => {
            const results = [];

            if (data) {
              for (let x = 0; x < data.length; x += 1) {
                results.push({
                  value: data[x].id,
                  label: data[x].club_name,
                  sportId: data[x].federation_name,
                });
              }
            }

            update(results);
          },
          complete: () => $('.club-selector-spinner').hide(),
        });
      },
      onSelect: (item) => {
        $('.club-selector').val(item.label);
        if (Visitor.saveClub({ id: item.value, name: item.label, sportId: item.sportId })) {
          Start.showTheClubIsSavedMessage();
        }
      },
    });
  }

  static chooseSport(e) {
    // Prevent closing Start menu
    e.preventDefault();
    e.stopPropagation();

    const sportSelection = {
      id: $(e.currentTarget).data('choose-sport-id'),
      name: $(e.currentTarget).data('choose-sport-name'),
    };

    Start.saveSport(sportSelection);
    Start.setSport(sportSelection);
  }

  static returnToStart(e) {
    // Prevent closing Start menu
    e.preventDefault();
    e.stopPropagation();

    Start.hideTheSportCouldNotBeSavedMessage();

    $('.start-menu-start').removeClass('d-none');
    $('.start-menu-sport').removeClass('d-block');
  }

  static saveSport(sport) {
    if (!Visitor.saveSport(sport)) {
      Start.showTheSportCouldNotBeSavedMessage();
    }
  }

  static showTheSportCouldNotBeSavedMessage() {
    $('.could-not-save-sport').show();
  }

  static hideTheSportCouldNotBeSavedMessage() {
    $('.could-not-save-sport').hide();
  }

  static setSport(sport) {
    if (!sport) {
      return;
    }

    if ($(`[data-choose-sport-id=${sport.id}]`).length === 0) {
      throw new Error('Sport doesn\'t exist');
    }

    $('[data-choose-sport-id]').removeClass('active-sport');
    $(`[data-choose-sport-id=${sport.id}]`).addClass('active-sport');

    $('.start-menu-start').addClass('d-none');
    $('.start-menu-sport').removeClass('d-block');
    $(`.start-menu-sport[data-sport-id=${sport.id}]`).addClass('d-block');

    $('.start-menu .forget-sport').removeClass('d-none');
  }

  static forgetSport(e) {
    e.preventDefault();
    e.stopPropagation();

    $('[data-choose-sport-id]').removeClass('active-sport');
    $('.start-menu .forget-sport').addClass('d-none');

    Visitor.saveSport(null);
  }

  static showTheClubIsSavedMessage() {
    $('.club-saved').fadeIn();
  }

  static hideTheClubIsSavedMessage() {
    $('.club-saved').hide();
  }
}
