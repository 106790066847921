export default class SmartContent {
  static init() {
    SmartContent.updateContentIfNecessary();

    window.document.addEventListener('preferences-registered', () => {
      SmartContent.updateContentIfNecessary();
    });
  }

  static updateContentIfNecessary() {
    if (!window.IntersectionObserver) {
      return;
    }

    const smartContentObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return;
          }
          
          observer.unobserve(entry.target);

          const smartContentElement = $(entry.target);
          const scriptElement = smartContentElement.children('script').first();

          if (!scriptElement.is('script[type=\'application/json\']')) {
            return;
          }

          const json = scriptElement.text();
          
          if (!json) {
            return;
          }

          SmartContent.startLoadingDisplay(smartContentElement);

          $.ajax('/wp-json/sportlink/v1/update-content', {
            cache: false,
            dataType: 'json',
            processData: false,
            data: json,
            method: 'POST',
            success: (data) => {
              if (data) {
                smartContentElement.html($(data).children());
              }
            },
            error: () => {
            },
            complete: () => {
              SmartContent.endLoadingDisplay(smartContentElement);
            },
          });
        });
      },
      { rootMargin: '0px 0px 800px 0px' },
    );

    document.querySelectorAll('.smart-content').forEach(element => smartContentObserver.observe(element));
  }

  static startLoadingDisplay(el) {
    const spinnerJsonUrl = '/wp-content/themes/sportlink/dist/static/images/spinner.json';

    el.addClass('updating-content');
    el.prepend(`
      <div class="smart-content-loader">
        <lottie-player
          src="${spinnerJsonUrl}"
          class="spinner"
          background="transparent"
          speed="1"
          loop autoplay></lottie-player>
      </div>`);
  }

  static endLoadingDisplay(el) {
    el.removeClass('updating-content');
    $('.smart-content-loader', el).remove();
  }
}
